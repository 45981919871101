import React, { createContext, useEffect, useReducer } from "react";
import { isBrowser } from "../hooks/auth";
import { InitialValueType } from "./interfaces";
import { reducer } from "./reducer";

const initialvalue: InitialValueType = {
  layer: false,
  centerLayerType: "message",
  centerLayerAction: undefined,
  opportunities: [],
  active: "",
  user: "",
  message: {
    __type: null,
    message: null,
  },
  adUsers: [],
  ddmUsers: [],
  sessionTag: {
    __type: "applicant",
    username: "",
    position: "BIM Engineer",
    company: "Ace Group",
    nationality: false,
    date: "",
  },
  filter: {
    keywords: "",
    location: "",
    position: "",
    rm: "",
    softwares: "",
  },
  admin: {
    selectedEngineer: "",
    activeOpportunity: null,
    activeIndex: 0,
  },
};

const initializer = () => {
  if (!isBrowser()) return;

  let hasLocal = localStorage.getItem("localProvider");
  if (hasLocal) return JSON.parse(hasLocal);
  return initialvalue;
};

export const GlobalStateContext =
  createContext<InitialValueType | null>(null);
export const GlobalDispatchContext = createContext(null);

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, [], initializer);

  useEffect(() => {
    try {
      localStorage.setItem("localProvider", JSON.stringify(state));
    } catch (err) {
      console.log("unable to set localStorage", err);
    }
  }, [state]);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
