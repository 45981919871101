// AppContainer.js
import React from "react";
import { Grommet, Box } from "grommet";
import { theme } from "../../assets/theme";

// Theme-like object specifying alignment, width, and spacing for
// an AppContainer.
export const appContainer = {
  gap: "large",
  width: {
    min: "medium",
  },
};

export const AppContainer = ({ ...rest }) => (
  <Grommet theme={theme}>
    <Box
      gap={appContainer.gap}
      height={{ min: "100vh" }}
      width={appContainer.width}
      flex={false}
      {...rest}
    />
  </Grommet>
);
