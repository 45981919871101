import awsmobile from "./aws-exports";

const redirectSignInVar = () => {
  if (process.env.NODE_ENV === "development") {
    return `http://localhost:8000/employee/resources/`;
  }
  return `${process.env.GATSBY_REDIRECT_SIGNIN_URL}/employee/resources/`;
};

const awsConfig = {
  ...awsmobile,
  oauth: {
    domain: awsmobile.oauth.domain,
    scope: [
      "email",
      "openid",
      "aws.cognito.signin.user.admin",
      "profile",
    ],
    redirectSignIn: redirectSignInVar(),
    redirectSignOut: `${process.env.GATSBY_REDIRECT_SIGNIN_URL}/user/login/`,
    responseType: "code",
  },
};

export default awsConfig;
