import { ThemeType } from 'grommet';
import { hpe } from 'grommet-theme-hpe'
import { deepMerge } from 'grommet/utils';

const customTheme: ThemeType = {
  global: {
    colors: {
      brand: "#F9D905",
      brand2: "#003591",
      selected: "brand",
      focus: 'brand',
      active: 'brand',
    },
    selected: {
      background: 'brand',
    },
  },
  checkBox: {
    color: "red",
  },
  select: {
    options: {
      text: {
        style: {
          background: 'brand',
        }
      }
    },
    icons: {
      color: "#000",
    }
  },
  button: {
    primary: {
      background: { color: 'brand' },
      color: 'dark-1',
      font: { weight: 'bold' },
    },
  },
}

export const theme = deepMerge(hpe, customTheme)
