import { Amplify } from "aws-amplify";
import React from "react";
import awsConfig from "./src/awsConfig";
import { CenterLayer } from "./src/components/CenterLayer";
import { AppContainer } from "./src/components/Layout";
import GlobalContextProvider from "./src/context/GlobalContextProvider";
import "./styles.css";
import "./lodingpage.css";
import "./preloader.js";

Amplify.configure(awsConfig);

function setApplicationMonitor() {
  // see: https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM.html
  (function (n, i, v, r, s, c, x, z) {
    x = window.AwsRumClient = {
      q: [],
      n: n,
      i: i,
      v: v,
      r: r,
      c: c,
    };

    window[n] = function (c, p) {
      x.q.push({ c: c, p: p });
    };
    z = document.createElement("script");
    z.async = true;
    z.src = s;
    document.head.appendChild(z);
  })(
    "cwr",
    "9cbcecde-b019-4c10-8181-2b3ec22651fe",
    "1.0.0",
    "ap-southeast-1",
    "https://client.rum.us-east-1.amazonaws.com/1.0.2/cwr.js",
    {
      sessionSampleRate: 1,
      guestRoleArn:
        "arn:aws:iam::517820625953:role/RUM-Monitor-ap-southeast-1-517820625953-4299412749361-Unauth",
      identityPoolId:
        "ap-southeast-1:e07740b0-3e62-4cd7-ae00-09cae17a4724",
      endpoint: "https://dataplane.rum.ap-southeast-1.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: false,
    }
  );
}

export const registerServiceWorker = () => true;

export const onServiceWorkerUpdateReady = () => {
  // const answer = window.confirm(
  //   `This application has been updated. ` +
  //     `Reload to display the latest version?`
  // );
  // if (answer === true) {
  //   window.location.reload();
  // }
  // FORCE RELOAD OF PAGE WHEN THERE ARE UPDATES
  window.location.reload();
};

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <CenterLayer>
      <AppContainer>{element}</AppContainer>
    </CenterLayer>
  );
};

export const onInitialClientRender = () => {
  setApplicationMonitor();
};
