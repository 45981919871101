/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://hvzqkd2o7jg5bovrl26sixw6ku.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-flg3hncfhnav7opuwchkf6sdji",
    "aws_cognito_identity_pool_id": "ap-southeast-1:9d3a25c4-d205-425e-aad8-ed0860c5370b",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_5dk5esDmV",
    "aws_user_pools_web_client_id": "5e5rtgct9jilo7lfd6r4fcnv0o",
    "oauth": {
        "domain": "acesv29d6e8ab7-9d6e8ab7-production.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://aces.aceplp.com.sg/employee/resources/,http://localhost:8000/employee/resources/",
        "redirectSignOut": "https://aces.aceplp.com.sg/user/login/,http://localhost:8000/user/login/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "acesv2s3uploads54431-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
