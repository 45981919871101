import React, { useContext } from "react";
import { Box, Button, Heading, Layer, Text } from "grommet";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";
import { isBrowser } from "../hooks/auth";

export const CenterLayer = ({ children, props }) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const { layer, centerLayerType } =
    state === null ? undefined : state;
  const { error, message } =
    state === null ? undefined : state.message;

  const buttonColor = () => {
    if (error) return "status-critical";
    else return "green";
  };

  function onClose() {
    dispatch({ type: "TOGGLE_LAYER" });
  }

  function onSubmit() {
    dispatch({ type: "START_ACTION" });
  }

  // function onOpen() {
  // }
  return (
    <>
      {layer ? (
        <Layer
          id="error-layer"
          position="center"
          style={{ zIndex: 1000000 }}
          onClickOutside={onClose}
          onEsc={onClose}
        >
          <Box pad="medium" gap="small" width="medium">
            {state.centerLayerType !== "action" ? (
              <Heading level={3} margin="none">
                {error ? "Oops!" : "Success!"}
              </Heading>
            ) : null}
            <Text>{message || "Error detected"}</Text>
            <Box
              as="footer"
              gap="small"
              direction="row"
              align="center"
              justify="end"
              pad={{ top: "medium", bottom: "small" }}
            >
              {state.centerLayerType.includes("action") ? (
                <Box direction="row" gap="small">
                  <Button
                    label={
                      <Text color="white">
                        <strong>
                          {error === 400 ? "Back" : "Cancel"}
                        </strong>
                      </Text>
                    }
                    onClick={onClose}
                    primary
                    color="status-critical"
                  />
                  <Button
                    label={
                      <Text color="white">
                        <strong>
                          {error === 400 ? "Back" : "Ok"}
                        </strong>
                      </Text>
                    }
                    onClick={onSubmit}
                    primary
                    color="green"
                  />
                </Box>
              ) : (
                //<Button label="Open 2" onClick={onOpen2} color="dark-3" />
                <Button
                  label={
                    <Text color="white">
                      <strong>{error === 400 ? "Back" : "Ok"}</strong>
                    </Text>
                  }
                  onClick={onClose}
                  primary
                  color={buttonColor()}
                />
              )}
            </Box>
          </Box>
        </Layer>
      ) : null}

      {children}
    </>
  );
};
